<template>
  <div class="addons flex flex-col">
    <div v-if="user.role == 'admin'" class="w-full bg-action-bar px-2 py-2 clearfix">
      <div class="float-right flex flex-wrap">
        <div>
          <router-link class="btn-green" to="/addon/create">
            {{ $t("addons_page.namge_additional_service") }}
            <BaseIcon icon="plus" class="ml-2" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="w-full mt-6">
      <vue-good-table
        styleClass="vgt-table"
        row-style-class="text-sm"
        :columns="columns"
        :rows="addons"
        :search-options="{
          enabled: false,
        }"
        :pagination-options="{
          enabled: false,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'edit'">
            <button class="btn-blue-outline" @click.prevent="showUpdate(props.row)">
              <BaseIcon icon="pencil-alt" />
            </button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
    <AddonUpdate :addonInfo="updateInfo" @updateAddon="getAddons()" />
  </div>
</template>

<script>
const MODAL_WIDTH = 850;

import AddonUpdate from "@/components/modal/addon_update";

export default {
  name: "Addons",
  title() {
    return `Admin - Tilläggstjänster`;
  },
  components: {
    AddonUpdate,
  },
  data() {
    return {
      addons: [],
      updateInfo: null,
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    columns() {
      return [
        { label: this.$t("addons_page.name"), field: "name", sortable: true },
        { label: this.$t("addons_page.id"), field: "addon_id", sortable: false },
        { label: "", field: "edit", sortable: false },
      ];
    },
  },

  methods: {
    getAddons() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/addons`)
        .then((response) => {
          this.addons = response.data.addons;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    showUpdate(addon) {
      this.updateInfo = _.cloneDeep(addon);
      this.$modal.show("update-addon");
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
    this.getAddons();
    this.setPageTitle(`Admin - Tilläggstjänster`, "addons");
  },
};
</script>
