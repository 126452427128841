<template>
  <modal name="update-addon" class="modal-inner modal-update-addon" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true">
    <span class="close-button" @click="hide('update-addon')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header text-lg bg-primary-50 font-serif">
      <span v-if="updateInfo">{{ updateInfo.name }}</span>
    </div>
    <div class="modal-body">
      <ValidationObserver ref="formUpdateAddon" v-if="updateInfo != null">
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseInput v-model="updateInfo.name" type="text" field_name="Namn" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <BaseTextarea rows="5" v-model="updateInfo.description" field_name="Beskrivning" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full px-2 py-2">
            <label class="text-gray-600 text-sm font-bold pb-2 font-serif">Bild<b class="text-red-500 ml-1">*</b></label>
            <img :src="previewImage" class="px-2 py-2 max-h-64" />
            <input type="file" accept="image/*" ref="imageUpdate" @change="fileChangeUpdate()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" />
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div class="modal-footer">
      <div class="footer-container clearfix">
        <button class="btn-red-outline px-3 py-2 float-left" @click="hide('update-addon')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
        <button class="btn-green px-3 py-2 float-right" @click="updateAddon()">Spara<BaseIcon icon="save" class="ml-1" /></button>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 600;

export default {
  props: {
    addonInfo: {
      required: true,
      default: null,
    },
  },
  data() {
    return {
      updateInfo: null,
      updateImage: null,
      previewImage: null,
    };
  },

  watch: {
    addonInfo(newVal) {
      this.updateInfo = _.cloneDeep(newVal);
      this.previewImage = this.updateInfo.image;
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  methods: {
    updateAddon() {
      this.$refs.formUpdateAddon.validate().then((success) => {
        if (!success) {
          return;
        }

        let headers = {
          "Content-Type": "multipart/form-data",
          "X-Access-Token": this.$store.state.token,
        };

        var formData = new FormData();

        formData.append("name", this.updateInfo.name);
        formData.append("description", this.updateInfo.description);

        if (this.updateImage) formData.append("image", this.updateImage);

        this.axios
          .put(`${process.env.VUE_APP_SERVER_URL}/addon/${this.updateInfo._id}`, formData, headers)
          .then(() => {
            // eslint-disable-line
            this.$emit("updateAddon");
            this.$modal.hide("update-addon");
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    fileChangeUpdate() {
      var vm = this;

      if (this.$refs.imageUpdate.files.length > 0) {
        this.updateImage = this.$refs.imageUpdate.files[0];

        let fileReader = new FileReader();
        fileReader.onload = function(e) {
          vm.previewImage = e.target.result;
        };
        fileReader.readAsDataURL(this.updateImage);
      } else {
        this.updateImage = null;
        this.previewImage = this.updateInfo.image;
      }
    },

    hide(id) {
      this.$refs.formUpdateAddon.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
